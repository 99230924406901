'use client';

import { Link } from 'components/link';
import { ChevronRightIcon } from 'components/icon';
import { MightHelpType } from 'types/product';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function MightHelp({
  items,
  language,
  region,
}: {
  items: MightHelpType[];
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  if (!items) return null;

  return (
    <ul className='divide-gray-light divide-y'>
      {items.map((item, index: number) => (
        <li key={`need-hel${index}`}>
          <Link
            href={`/${item.faq_item.link}`}
            className='flex items-center justify-between py-4'
          >
            <span className='text-custom-sm font-semibold text-gray-dark'>
              {item.faq_item.label?.[language]}
            </span>
            <ChevronRightIcon className='text-gray rtl:rotate-180' />
          </Link>
        </li>
      ))}
    </ul>
  );
}
