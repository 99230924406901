import Image from 'next/image';
import ContentfulLink from 'components/contentful-link';
import { NeedHelpType } from 'types/product';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function NeedHelpInformation({
  item,
  language,
  region,
}: {
  item: NeedHelpType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  if (!item) return null;

  return (
    <ContentfulLink
      item={item}
      className='flex flex-col items-center space-y-3 rounded-xl bg-white p-3 shadow-standart'
      language={language}
      region={region}
    >
      <div className='relative flex h-[54px] w-[54px] items-center justify-center rounded-full bg-blue/10'>
        <Image
          src={item.icon}
          alt={item.label[language]}
          width={0}
          height={0}
          sizes='100vw'
          className='h-full w-full'
        />
      </div>

      <div className='flex flex-col items-center space-y-1 text-center'>
        <span className='text-custom-sm font-semibold text-blue'>
          {item.label[language]}
        </span>
        {item.duration && (
          <span className='!mt-2 text-[9px] font-semibold text-gray'>
            {item.duration[language]}
          </span>
        )}
      </div>
    </ContentfulLink>
  );
}
